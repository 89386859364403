import React from 'react'
import useWindowSize from "../hooks/useWindowSize"
import styled from "styled-components"


function AmotzComponent(props) {
    const windowSize = useWindowSize();

    return (windowSize > 480) ? (
        <div>
            <p>This is a component</p>
            <p>{props.title}</p>
            <p>{props.whatev}</p>
        </div>
        
    ) : (
        <p>This is a mobile component</p>
    )
}

export default AmotzComponent
