import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import "./carouselRash.css"
import "../../css/typography.css"
import Fire from "../../images/rash/fire-rash.png"
import Fox from "../../images/rash/fox-rash.png"
import Gilat from "../../images/rash/gilat-rash.png"
import Kfar from "../../images/rash/kfar-rash.png"
import Misrad from "../../images/rash/misrad-rash.png"
import Ofakim from "../../images/rash/ofakim-rash.png"
import Sixt from "../../images/rash/sixt-rash.png"
import Sparta from "../../images/rash/sparta-rash.png"
import Weizmann from "../../images/rash/weizmann-rash.png"
import useWindowSize from "../../hooks/useWindowSize"
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticImage } from "gatsby-plugin-image"



function CarouselHome() {

    const windowSize = useWindowSize();

    return (windowSize > 480) ?  (
        <div style={{position: 'relative', height: '30vw', backgroundColor: 'lightgray', display: 'flex', alignItems: 'center'}}>
        <div className='rash-slide-title'>
            <p dir='rtl'>לקוחות מרוצים</p>
        </div>
        <Carousel>
                <Carousel.Item interval={9000}>
                    <div className='rash-slide'>
                        <div className='car-rash-img-div'>
                            <div className='rash-car-img'>
                                <StaticImage src="../../images/rash/fire-rash.png" alt='fire'/>
                            </div>
                            <div className='rash-car-img'>
                                <StaticImage src='../../images/rash/kfar-rash.png' alt='kfar' />
                            </div>
                            <div className='rash-car-img-misrad'>
                                <StaticImage src='../../images/rash/misrad-rash.png' alt='misrad' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={9000}>
                    <div className='rash-slide'>
                        <div className='car-rash-img-div'>
                            <div className='rash-car-img-weiz'>
                                <StaticImage src='../../images/rash/weizmann-rash.png' alt='fire'/>
                            </div>
                            <div className='rash-car-img'>
                                <StaticImage src='../../images/rash/ofakim-rash.png' alt='kfar' />
                            </div>
                            <div className='rash-car-img-misrad'>
                                <StaticImage src='../../images/rash/fox-rash.png' alt='misrad' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={9000}>
                    <div className='rash-slide'>
                        <div className='car-rash-img-div'>
                            <div className='rash-car-img-gilat'>
                                <StaticImage src='../../images/rash/gilat-rash.png' alt='fire'/>
                            </div>
                            <div className='rash-car-img-sixt'>
                                <StaticImage src='../../images/rash/sixt-rash.png' alt='kfar' />
                            </div>
                            <div className='rash-car-img-sparta'>
                                <StaticImage src='../../images/rash/sparta-rash.png' alt='misrad' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
            </div>
    ) : 
    (
        <div style={{position: 'relative', height: '80vw', backgroundColor: 'lightgray', display: 'flex', alignItems: 'center'}}>
        <div className='rash-slide-title'>
            <p dir='rtl'>לקוחות מרוצים</p>
        </div>
        <Carousel style={{zIndex: '1'}}>
                <Carousel.Item interval={9000}>
                    <div className='rash-slide'>
                        <div className='car-rash-img-div'>
                            <div className='rash-car-img-fire'>
                                <StaticImage src='../../images/rash/fire-rash.png' alt='fire'/>
                            </div>
                            <div className='rash-car-img'>
                                <StaticImage src='../../images/rash/kfar-rash.png' alt='kfar' />
                            </div>
                        </div>
                        <div className='car-rash-img-div-2'>
                            <div className='rash-car-img-misrad'>
                                <StaticImage src='../../images/rash/misrad-rash.png' alt='misrad' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={9000}>
                    <div className='rash-slide'>
                        <div className='car-rash-img-div'>
                            <div className='rash-car-img-weiz'>
                                <StaticImage src='../../images/rash/weizmann-rash.png' alt='fire'/>
                            </div>
                            <div className='rash-car-img'>
                                <StaticImage src='../../images/rash/ofakim-rash.png' alt='kfar' />
                            </div>
                        </div>
                        <div className='car-rash-img-div-2'>
                            <div className='rash-car-img-misrad'>
                                <StaticImage src='../../images/rash/fox-rash.png' alt='misrad' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={9000}>
                    <div className='rash-slide'>
                        <div className='car-rash-img-div'>
                            <div className='rash-car-img-gilat'>
                                <StaticImage src='../../images/rash/gilat-rash.png' alt='fire'/>
                            </div>
                            <div className='rash-car-img-sixt'>
                                <StaticImage src='../../images/rash/sixt-rash.png' alt='kfar' />
                            </div>
                        </div>
                        <div className='car-rash-img-div-2'>
                            <div className='rash-car-img-sparta'>
                                <StaticImage src='../../images/rash/sparta-rash.png' alt='misrad' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
            </div>
    )
}

export default CarouselHome