import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import useWindowSize from "../hooks/useWindowSize";
import Logo from "../images/mobile-logo.png"
import "../css/typography.css"
import "../css/rashuyot.css"
import Alert from "../images/features/alert.png"
import Architecture from "../images/features/architecture.png"
import Command from "../images/features/command.png"
import Computing from "../images/features/computing.png"
import Intel from "../images/features/intel.png"
import Locked from "../images/features/locked-phone.png"
import Position from "../images/features/position.png"
import Sens from "../images/features/sens.png"
import CarRash from "../components/rashuyot/carouselRash"
import RashForm from "../components/contact/rashContact"
import Mac from "../images/rash/mac.png"
import Mobile from "../images/rash/mobile.png"
import RedArribaDerecha from "../images/redes2/arriba-derecha.png"
import RedAbajoIzquierda from "../images/redes2/home-izquierda.png"

const FirstSection = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 5vw;
    padding-right: 7vw;
    position: relative;

    div {
        width: 55vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    div p {
      font-family: 'Varela';
      font-size: 3.5vw;
      line-height: 4vw;
      width: 40vw;
    }

    @media only screen and (max-width: 480px) {
      width: 100vw;
      display: flex;
      flex-direction: column;
      padding-top: 10vw;
      padding-right: 0;
      position: relative;

      div p {
      font-family: 'Varela';
      font-size: 7.5vw;
      line-height: 9vw;
      width: 80vw;
    }
    }
`

const ThirdSection = styled.div`
  padding-top: 5%;
  font-family: 'Varela';

  @media only screen and (max-width: 480px) { 
    padding-top: 15%;
  }
`


const Rashuyot = () => {
  const windowSize = useWindowSize();
  const [hasMounted, setHasMounted] = useState(false) 

useEffect(() => {
    setHasMounted(true)
}, [])


if (windowSize > 480) {
    return hasMounted ? (
        <Layout>
      <div style={{width: '100vw'}}>

      <FirstSection>
          <div>
              <img src={Logo} alt="sayvu-logo" style={{width: '40%', marginBottom: '3%'}}/>
              <p style={{textAlign: 'right', fontWeight: 'bold', marginBottom: '5%'}}>פלטפורמה חדשנית מטכנולוגיה ישראלית, המאפשרת ניהול אחוד ושליטה מלאה במתרחש<span style={{color: '#c4132a'}}> מכל מקום ובכל זמן</span></p>
          </div>
          <img src={Mac} alt="mac" className='rash-mac'/>
          <img src={Mobile} alt="mobile" className='rash-mobile'/>
      </FirstSection>
      
      <div className="second-section-rash">

        <div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>אבטחה עירונית מלאה כולל מוסדות חינוך, אגף הרווחה ומאוימים</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>ניהול כלל המגיבים הראשונים ברשות: שיטור עירוני, מאבטחי מוסדות חינוך, שיטור משולב, מאבטחי משרד הרווחה</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>התראות והזנקת מגיבים ראשונים</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>תיקי שטח ופרוטוקולים</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>GIS ניהול מידע על</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>התממשקות למערכות ליבה</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>ניהול מתנדבים וצוותי חירום</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
          <div className='rash-paragraph-div'>
            <p className='rash-video-text'>מימון ממשלתי לזכאים</p>
            <p className='rash-separator'>{'<'}</p>
          </div>
        </div>

        <iframe width="40%" height="40%" src="https://www.youtube.com/embed/eB_c6CrImgY" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen style={{marginRight: '6vw'}}></iframe>
      </div>

      <ThirdSection>
        <div className='rash-png-main-div'>
          <div className='rash-png-div'>
            <img src={Locked} alt="feature-logo" />
            <p dir='rtl'>דיווח מטלפון נעול</p>
          </div>
          <div className='rash-png-div'>
            <img src={Computing} alt="feature-logo" />
            <p dir='rtl'>כפתורי מצוקה פיזיים</p>
          </div>         
          <div className='rash-png-div'>
            <img src={Command} alt="feature-logo" />
            <p dir='rtl'>מוקד שליטה אחוד וחכם</p>
          </div>          
          <div className='rash-png-div'>
            <img src={Architecture} alt="feature-logo" />
            <p dir='rtl'>ארכיטקטורה גמישה - התקנה בתוך מספר ימים</p>
          </div>        
        </div>

          
        <div className='rash-png-main-div'>
          <div className='rash-png-div'>
            <img src={Position} alt="feature-logo" />
            <p dir='rtl'>מיקום תוך מבני</p>
          </div>
          <div className='rash-png-div'>
            <img src={Alert} alt="feature-logo" />
            <p dir='rtl'>התראות אוטומטיות</p>
          </div>         
          <div className='rash-png-div'>
            <img src={Sens} alt="feature-logo" />
            <p dir='rtl'>טכנולוגיית חיישנים מתקדמת</p>
          </div>          
          <div className='rash-png-div'>
            <img src={Intel} alt="feature-logo" />
            <p dir='rtl'>הפעלה וזיהוי קולי באמצעות בינה מלאכותית</p>
          </div>
        </div>
      </ThirdSection>

      <div>
      </div>

      <CarRash />

      <div className='rash-form-div'>
        <p className='rash-form-title'>צרו קשר</p>
        <p className='rash-form-subtitle'>לפרטים נוספים ולקביעת מועד להדגמת המערכת, אנא השאירו פרטים</p>
        <RashForm />
      </div>
      </div>

      <div>
                <img src={RedArribaDerecha} alt="red Arriba" style={{position: 'absolute', width: '20%', top: '10%', right: '0', zIndex: '0'}}/>
            </div>
            <div>
                <img src={RedAbajoIzquierda} alt="red abajo izq" style={{position: 'absolute', width: '20%', top: '120%', left: '0', zIndex: '-5'}}/>
        </div> 
      </Layout>

) : (
    null
)
} else {
return hasMounted ? (
    <Layout>
  <div>
      <FirstSection>
          <div style={{marginLeft: '30%'}}>
              <p style={{textAlign: 'right', fontWeight: 'bold', marginBottom: '5%'}}>פלטפורמה חדשנית מטכנולוגיה ישראלית, המאפשרת ניהול אחוד ושליטה מלאה במתרחש<span style={{color: '#c4132a'}}> מכל מקום ובכל זמן</span></p>
          </div>
          <div style={{position: 'relative', height: '60vw'}}>
            <img src={Mac} alt="mac" className='rash-mac'/>
            <img src={Mobile} alt="mobile" className='rash-mobile'/>
          </div>
      </FirstSection>

      <div dir='rtl' className="second-section-rash"  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden'}}>

<div style={{marginTop: '-30%'}}>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>אבטחה עירונית מלאה כולל מוסדות חינוך, אגף הרווחה ומאוימים</p>
  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>ניהול כלל המגיבים הראשונים ברשות: שיטור עירוני, מאבטחי מוסדות חינוך, שיטור משולב, מאבטחי משרד הרווחה</p>
  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>התראות והזנקת מגיבים ראשונים</p>
  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>תיקי שטח ופרוטוקולים</p>
  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>ניהול מידע על GIS</p>
  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>התממשקות למערכות ליבה</p>
  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>ניהול מתנדבים וצוותי חירום</p>

  </div>
  <div className='rash-paragraph-div'>
    <p className='rash-separator'>{'>'}</p>
    <p className='rash-video-text'>מימון ממשלתי לזכאים</p>
  </div>
</div>

<div style={{display: 'flex', justifyContent: 'center', 
width: '100vw', marginTop: '0%'}}>
  <iframe width="80%" height="150%" src="https://www.youtube.com/embed/eB_c6CrImgY" 
  title="YouTube video player" 
  frameborder="0" 
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
  allowfullscreen></iframe>
</div>

</div>

<ThirdSection>
        <div className='rash-png-main-div'>
          <div className='rash-png-div'>
            <img src={Locked} alt="feature-logo" />
            <p dir='rtl'>דיווח מטלפון נעול</p>
          </div>
          <div className='rash-png-div'>
            <img src={Computing} alt="feature-logo" />
            <p dir='rtl'>כפתורי מצוקה פיזיים</p>
          </div> 
        </div> 

        <div className='rash-png-main-div'>
          <div className='rash-png-div'>
            <img src={Command} alt="feature-logo" />
            <p dir='rtl'>מוקד שליטה אחוד וחכם</p>
          </div>          
          <div className='rash-png-div'>
            <img src={Architecture} alt="feature-logo" />
            <p dir='rtl'>ארכיטקטורה גמישה - התקנה בתוך מספר ימים</p>
          </div>        
        </div>
        
        <div className='rash-png-main-div'>
          <div className='rash-png-div'>
            <img src={Position} alt="feature-logo" />
            <p dir='rtl'>מיקום תוך מבני</p>
          </div>          
          <div className='rash-png-div'>
            <img src={Alert} alt="feature-logo" />
            <p dir='rtl'>התראות אוטומטיות</p>
          </div>        
        </div>

        <div className='rash-png-main-div'>
          <div className='rash-png-div'>
            <img src={Sens} alt="feature-logo" />
            <p dir='rtl'>טכנולוגיית חיישנים מתקדמת</p>
          </div>          
          <div className='rash-png-div'>
            <img src={Intel} alt="feature-logo" />
            <p dir='rtl'>הפעלה וזיהוי קולי באמצעות בינה מלאכותית</p>
          </div>        
        </div>
          
      </ThirdSection>

      <CarRash />

      <div className='rash-form-div'>
          <p className='rash-form-title'>צרו קשר</p>
          <p className='rash-form-subtitle'>לפרטים נוספים ולקביעת מועד להדגמת המערכת, אנא השאירו פרטים</p>
          <RashForm />
      </div>
  </div>
  <div>
                <img src={RedArribaDerecha} alt="red Arriba" style={{position: 'absolute', width: '43%', top: '40%', right: '0', zIndex: '-5'}}/>
            </div>
            <div>
                <img src={RedAbajoIzquierda} alt="red abajo izq" style={{position: 'absolute', width: '50%', top: '120%', left: '0', zIndex: '-5'}}/>
            </div>  
</Layout> ) : (
    null
)
}
}

export default Rashuyot
