import React, {useEffect, useState} from 'react'
import useWindowSize from "../hooks/useWindowSize"
import "../css/typography.css"
import Layout from "../components/layout"
import AmotzComponent from "../components/amotzComponent"




function Amotz() {
    const [hasMounted, setHasMounted] = useState(false) 
    const windowSize = useWindowSize();

    useEffect(() => {
        setHasMounted(true)
    }, [])

    if (windowSize > 480) {
        return hasMounted ? (
        <Layout>
            <p>DESKTOP VERSION AND HOT RELOADING</p>
            <p>IN REAL TIME</p>
            <AmotzComponent 
            title='This is the title of the component'
            whatev='Whatever'
            />
        </Layout>
        ) : (
        null
    )
} else {
return hasMounted ? (
        <Layout>
            <p>MOBILE VERSION</p>
        </Layout>
    ) : (
        null
    )
}
}

export default Amotz
