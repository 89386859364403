import React from 'react'
import styled from "styled-components"
import "../../css/typography.css"
import Typewriter from "typewriter-effect"
import "./typewriter.css"

const TypewriterDiv = styled.div`
    font-family: 'G Medium';
    font-size: 5vw;
    width: 40vw;
    height: 18vw;
    margin-bottom: 10%;
    margin-top: -10%;
`


function TypeEffect() {
    return (
        <TypewriterDiv>
            <Typewriter         
            onInit={(typewriter) => {
                typewriter.typeString("Secure the world <span style='color: #c4132a'>together</span>")
                .pauseFor(3000)
                .deleteAll(20)
                .typeString("Save lives <span style='color: #c4132a'>easier</span>")
                .pauseFor(3000)
                .deleteAll(20)
                .start()
            }}

            options={{
                loop: true,
                delay: 70,
            }}
            />
        </TypewriterDiv>
            
        
    )
}

export default TypeEffect
