import React, {useEffect} from 'react'
import useWindowSize from "../hooks/useWindowSize"

function Redirect() {
    const windowSize = useWindowSize();


    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

          // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return "unknown";
    }

    useEffect(() => {
        let os = getMobileOperatingSystem();
        if (os == "Android") {
            window.location.assign('https://play.google.com/store/apps/details?id=com.sayvu');
        } else if (os == "iOS") {
            window.location.assign('https://apple.co/3cDUn5R​');
        } else if (os == "Windows Phone") {
            window.location.assign('https://play.google.com/store/apps/details?id=com.sayvu');
        } else {
            window.location.assign('https://apple.co/3cDUn5R​');
        }
    }, [])

    return (
        <>
        </>
    )
}

export default Redirect
