import React, {useState, useEffect} from 'react'
import useWindowSize from "../hooks/useWindowSize";
import Layout from "../components/layout"
import "../css/eula.css"

function AboutUs() {
    const [hasMounted, setHasMounted] = useState(false) 
    const windowSize = useWindowSize();

    useEffect(() => {
        setHasMounted(true)
    }, [])

        return hasMounted ? (
        <Layout>
            <div style={{padding: '5%'}}>
        <p><strong><u>End User License Agreement</u></strong></p>
        <p><strong><u>General:</u></strong></p>
        <p>This End User Agreement (hereinafter this &ldquo;<strong>License / EULA</strong>&rdquo;) is an agreement between you and SayVU Technologies Ltd., its subsidiaries and affiliates (hereinafter &ldquo;<strong>SayVU</strong>&rdquo;). This License
            governs your use of this application and all related software, documentation, updates and upgrades that replace or supplement the application and are not distributed with a separate license (together - the &ldquo;<strong>Application</strong>&rdquo;).
        </p>
        <p>
            This Application is intended and available to enable Licensed Users to report certain emergency situations and/or other situations, according to the specific Purpose of the Application determined in the Definition Window (defined herein). This Application
            is not intended for any other use other than for the Purpose of the Application and shall not enable and/or intended to enable any other reports of any kind, including but not limited to, any emergency situation reports and other critical
            situations reports, which are not specifically included in the Purpose of the Application.
        </p>

        <p><strong><u> Binding Agreement:</u></strong></p>
        <p>By clicking the &ldquo;<strong>I agree</strong>&rdquo; button when you first install the Application, you agree to be bound by the provisions of this EULA. If you do not agree to be bound by this EULA, you must click the &ldquo;<strong>I don't agree</strong>&rdquo;
            button during the installation process or otherwise not install or use the Application.</p>
        <p>
            IF YOU INSTALL THE APPLICATION, THE TERMS AND CONDITIONS OF THIS LICENSE, AS WELL AS THE TERMS AND CONDITIONS OF THE DEFINITION WINDOW, ARE FULLY ACCEPTED BY YOU.<strong> If you do not agree to any portion of this License, do not install or use the Application.</strong>
        </p>

        <p>
            Upon first installation of the Application and clicking the "I agree" button, the Application may ask you to integrate certain details and/or information, to enable the Application to verify your ascription to specific uses/features of the Application.
            Upon such verification, an additional window shall open (the &ldquo;<strong>Definition Window</strong>&rdquo;). The Definition Window stipulates the specific terms, conditions and details regarding your specific and personal use
            of the Application.
        </p>

        <p>
            The Definition Window is an integral part of this EULA. By clicking the "I agree" button on the Definition Window when you first install the Application, you agree to be bound by the provisions and terms specified in the Definition Window as an integral
            part of this EULA. If you do not agree, you must click the &ldquo;<strong>I don't agree</strong>&rdquo; button during the installation process.
        </p>

        <p>
            SayVU may make changes to this EULA from time to time, and in doing so will bring to your attention the fact thereof. A copy of the current document version shall be available at SayVU’s website. You shall have the option of discontinuing the use of the
            Application in accordance with terms hereof if the changes are unacceptable to you but your continuing use of the Application after the date on which you receive notice of changes, shall be deemed your acceptance of the updated EULA/terms.
        </p>

        <p><strong><u> Clarifications</u></strong></p>
        <p>Any misuse of the Application for any other purpose, other than for the Purpose of the Application and according to the terms of this License, may cause direct and indirect damages as well as significant expenses and financial loss, to SayVU and/or
            to third parties, such as authorities, rescue/emergency services and others. SayVU shall not be liable for any damages, expenses and/or loss accrued as a result of any misuse of the Application by any Licensed User, including in any case of
            a False Report (defined herein).</p>
        <p>
            As specified herein and according to SayVU`s Privacy Policy, the use of this Application as well as any report made by you using the Application, shall disclose your personal data to all Approved Receivers, including but not limited to, your location,
            your telephone number, your name, your IP address, as well as additional information, such as, different parameters and data from sensors/devices integrated on your smart- phone, which may be important/relevant for the Purpose of the Application,
            as well as, for any response to your reports and/or emergency situations. If you do not agree for these terms and disclosure, you must click the &ldquo;
            <strong>I don't agree</strong>&rdquo; button during the installation process.</p>

        <p>Please read this EULA carefully.</p>

        <p><strong>EULA AGREEMENT</strong></p>

        <ol class="lista-num">
            <li><strong><u> Definitions:</u></strong> </li>
            <p>Except to the extent expressly provided otherwise, in this EULA:</p>
            <ul>
                <li> 1.1 &ldquo;<strong>EULA / License</strong>&rdquo; means: this end user license agreement, including any amendments to this end user license agreement, as shall be effected from time to time;</li>
                <li> 1.2 &ldquo;<strong>Effective Date</strong>&rdquo; means: the date upon which you give your expressed consent to this EULA by clicking the "I agree" button upon first installation of the Application;</li>
                <li>1.3 &ldquo;<strong>Licensed User/s</strong>&rdquo; means: You and all other users chosen and determined for using this Application, as stipulated in the Definition Window.</li>
                <li>1.4 The &ldquo;<strong>Purpose of the Application</strong>&rdquo; means: the specific and sole purpose of your use of this Application, as stipulated in the Definition Window.</li>
                <li>1.5 &ldquo;<strong>Approved Receivers</strong>&rdquo; means: SayVU and/or any organ, entity, authority, rescue/emergency services and/or other third parties, stipulated in the Definition Window, which shall receive, handle, traffic and/or
                    respond to your reports and which shall be exposed to any disclosure of your Data, including without limitation your personal data and details collected and/or transferred by/through the Application.</li>
                <li>1.6 &ldquo;<strong>Valid Report/s</strong>&rdquo; means: Any true and valid report made by you or any on your behalf by using the Application, which was made according to this License and to the Application requirements, for the specific
                    Purpose of the Application.</li>
                <li>1.7 &ldquo;<strong>False Report</strong>&rdquo; means: any report which is in breach of the terms and condition of this License and/or the Application; and/or (ii) transferred, transmitted, sent broadcasted streamed or otherwise made available,
                    by any Licensed User or any on its behalf, either via graphic, video, verbal, photo and/or any other means, which contains any false/untrue events, facts, condition, matters, situations, false alert, notice, representation, report
                    and/or any other misleading/ untrue information/report/call.</li>
                <li>1.8 &ldquo;<strong>Misuse</strong>&rdquo; means: (i) any False Report; (ii) Creating a false identity for purposes of misleading others; (iii) Defaming, abusing, harassing, threaten, spamming, violating the rights of others and/or otherwise
                    interfering with others’ use of the Application; (iv) Publishing, transferring or distributing any inappropriate, indecent, obscene, foul or unlawful conduct; (v) Transferring, streaming or otherwise making available material that
                    contains viruses, Trojan horses, worms, time bombs, cancel bots, corrupted files or any other similar material that may damage the computer, telephone or other property of any third party, such as SayVU and/or any Approved Receiver
                    and/or another user; (vi) Use of any portion of the Application to harvest or collect information about others; (vii) Any use of the Application in order to interfere, harm, thwart and/or perform any other act that may create a False
                    Report and/or may, in any way or manner, interfere, harm, thwart any Approved Receiver and/or any response to any emergency situations.
                </li>
                <li>1.9 &ldquo;<strong>Proper Use</strong>&rdquo; means: the use of the Application according to all terms and conditions of the License and Application for reporting Valid Reports.</li>
                <li>1.10 &ldquo;<strong>Intellectual Property and Rights</strong>&rdquo; means: intellectual property and other rights and confidential information of SayVU and its licensors, wherever in the world, whether registrable or un-registrable, registered
                    or unregistered, including any application or right of application for such rights, including but not limited to, inventions, patents, patent applications, disclosures, concepts, algorithms, designs, drawings, diagrams, specifications,
                    formulas, procedures, techniques, methods, models, research, development, processes, “know-how”, “show-how”, data, software source or object code, documentation, other trade secrets, original works of authorship, copyrights, trademarks,
                    or trademark applications, information and materials relating to existing and future systems, technology, information and materials pertaining to related hardware, electronics, firmware, and software and all other intellectual property
                    rights, registered or unregistered.</li>
            </ul>

            <li><strong><u> License Grant and Terms of Use:</u></strong> </li>
            <ul>
                <br />
                <li>2.1 <strong>Grant:</strong> SayVU grants you a personal, limited, non-exclusive, non–transferrable, non- assignable, non-sub-licensable, license to install and use the Application for your personal, noncommercial use solely as set forth
                    in this License as well as in the Definition Window and any accompanying documentation, as shall be from time to time. Your licensed rights are subject to your compliance with this License. Any commercial use is prohibited. You are
                    expressly prohibited from sublicensing, renting, leasing or otherwise distributing the Application or rights to use the Application. The term of your license shall commence on the Effective Date, and shall end on the earlier of the
                    date that you dispose of the Application or SayVU's termination of this License (the “Term”). Granting the License as specified in this EULA is made for the exclusive purpose of enabling you to make Proper Use of the Application only,
                    according to the Purpose of the Application</li>
                <li>2.2 <strong>Application Updates; Availability:</strong> You agree that the Application may automatically download and install updates, upgrades and additional features that SayVU deems reasonable, beneficial to you and/or reasonably necessary.
                    You acknowledge and agree that any obligation SayVU may have to support the previous version(s) may expire or be terminated upon the availability of the update, upgrade and/or implementation of additional features. This License shall
                    apply to any updates, upgrades and/or additional features that are not distributed with a separate license or other agreement. SayVU shall use commercially reasonable efforts to make the Application available 24 hours a day, 7 days
                    a week during the term of this License, except for planned downtime, of which SayVU shall give you reasonable prior notice of or otherwise publish in its website. The use of the Application is subject to unavailability caused by circumstances
                    beyond SayVU’s reasonable control, including without limitation, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems, or power, communication or Internet service
                    provider failures or delays. </li>
                <li>2.3 <strong>Reservation of Rights:</strong>The Application is licensed, and not sold, to you for use only under the terms of this License. Except as expressly licensed to you herein, SayVU reserves all right, title and interest in the
                    Application and all and software delivered through the Application, including but not limited to, all Intellectual Property and Rights,. Nothing in this EULA shall operate to assign or transfer any Intellectual Property and Rights
                    from SayVU to you.</li>
                <li>2.4 <strong>Restrictions on Use: </strong> You must not, and shall not allow any Licensed User or third party to: (i) circumvent, disable or otherwise interfere with security-related features of the Application or features that prevent
                    or restrict use or copying of any content or that enforce limitations on use of the Application; (ii) allow any third party to use the Application; (iii) give, sell, rent, lease or timeshare the Application or use it in any service
                    bureau arrangement; (iv) reverse engineer, decompile or disassemble the Application or any components or Intellectual Property and Rights thereof; (v) use the Application to develop a competing service or product; (vi) use any robot,
                    spider, scraper, or other automated means to access or use the Application; (vii) take any action that imposes or may impose an unreasonable or disproportionately large load on the Application or SayVU’s infrastructure (as determined
                    by SayVU in its sole discretion); (viii) interfere or attempt to interfere with the integrity or proper working of the Application, or any related activities; (ix) make copies of the Application, or copy any ideas, features, functions
                    or graphics of the Application; (x) modify or create a derivative work of the Application, or any part thereof; (xi) reproduce, remove, deface, obscure, or alter SayVU’s or any third party's copyright notices, patents, trademarks,
                    or other proprietary rights affixed to or provided as part of the Application, or use or display logos of the Application differing from those of SayVU; and/or (xii) use the Application in any unlawful manner or in breach of this EULA.
                    Without limiting the foregoing, you may not use the Application to: (a) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (b) send or store infringing, obscene, threatening, libelous, or otherwise
                    unlawful or tortious material, including material harmful to children or which violates third party privacy rights; (c) send or store material containing viruses, worms, Trojan horses or other harmful computer code, files, scripts,
                    agents or programs; (d) interfere with or disrupt the integrity or performance of the Application; or (e) attempt to gain unauthorized access to the Application. </li>
                <li>2.5 <strong>Events of Misuse:</strong> In any event of a Misuse by Licensed User, including but not limited to, any concern or suspicion of Misuse by Licensed User, SayVU reserves the right, according to its discretion, to (i) Disconnect/block
                    user from the Application; and/or (ii) use any means required to protect the Application and/or any interest and/or rights of SayVU and/or of the Approved Receivers and/or any other third party; and/or (iii) Subject to applicable law,
                    disclose and submit user`s information, details and data to any relevant Approved Receivers and/or law enforcement authority, inter alia, for the purpose of executing legal remedies and suits against user.</li>
                    <li>2.6 <strong>Installation and Un-installation:</strong> Un-installation of the Application can be accomplished by removing the Application according to your smart-phone`s requirements.</li>
                <li>2.7 <strong> Applications: </strong> No third-party applications are required to access or use the Application, except for the recommended browsers, operating systems and Internet utilities described herein or on SayVU’s website. Any acquisition
                    or use by you of third-party products or services, including third-party applications, and any exchange of data between you and any third-party provider, is solely between you and the applicable third-party provider. We will not be
                    responsible for, and do not warrant, support or assume any liability for, any third-party products or services, whether or not SayVU has advised that they inter- operate with or can be used in conjunction with the Application. Any
                    recommendations, references or links that SayVU may provide to third party applications, or other products or services, are provided solely as a convenience to You. If you install or enable third-party applications to inter-operate
                    with or be used in conjunction with the Application, SayVU may allow providers of such third-party applications to access the Data as required for the inter- operation or use of such third-party applications with the Application. SayVU
                    shall not be responsible for any disclosure, modification or deletion of the Data resulting from any such access by third-party application providers.
                </li>
                <br />
                <li> 2.8 <strong><u>Privacy: </u></strong></li>
                <ul>
                    <li>2.8.1 You agree that by installing and using this Application you allow and permit SayVU to collect, use and store your personal data and personal details, including but not limited to, your Data, including name, your location, your
                        traveling information and location, your telephone number, your ip address, your smartphone battery status, your reports and any information, data, voice and/or video data included in your reports, and all other information, such
                        as different parameters and data from any sensor/device integrated on your smart-phone, which may be important/relevant for the Purpose of the Application, as well as, for any response to situation and/or emergency situations included
                        in the Purpose of the Application (&ldquo;
                        <strong>Data</strong>&ldquo;). You may upload, transmit, import, post or process your Data in the Application.</li>
                    <li>2.8.2 You acknowledge and agree that your Data may contain personally identifying information or personal data, as defined by the applicable laws which govern the use of data relating to identified or identifiable nature persons residing
                        in the EU and/or the state of California in the United states, including the laws of the European Union (&ldquo;<strong>EU</strong>&ldquo;) Data Protection Act 1998, the EU General Data Protection Regulation (&ldquo;
                        <strong>GDPR</strong>&ldquo;), and the California Consumer Protection Act effective as of January 1, 2020, as each of these laws are amended or replaced from time to time, and any other foreign or domestic laws to the extent that
                        they are applicable to the personally identifiable or personal data you upload, transmit, post or process while using the Application (&ldquo;<strong>Data Protection Laws and Regulations</strong>&ldquo;).</li>
                    <li>2.8.3 You hereby grant SayVU a non-exclusive right and license to receive, retrieve, access, use, reproduce, display, publish, copy, transmit, process and store your Data for your use of the Application for the Purpose of the Application,
                        in accordance with this Section 2.7 and SayVU’s privacy policy (&ldquo;<strong>Privacy Policy</strong>&ldquo;). To the extent that anything in this section 2.7 conflicts with the terms of the Privacy Policy, the terms of the Privacy
                        Policy shall prevail.</li>
                    <li>2.8.4 You retain ownership of your Data. You represent and warrant that: (i) you have lawfully obtained any personally identifying information or personal data described in Section 2.7.3 pursuant to any and all applicable and relevant
                        Data Protection Laws and Regulations; (ii) you have appropriately disclosed to your end-users or customers how personally identifiable information or personal data will be used, processed, stored and/or shared pursuant to any and
                        all applicable and relevant Data Protection Laws and Regulations; (iii) you are responsible for honoring any requests from your end-users or customers relating to the collection, use and storage of personally identifiable information
                        or personal data as required by any and all relevant Data Protection Laws and Regulations; (iv) you own or have acquired the right to all of the intellectual property rights subsisting in the Data, and have the right to provide
                        SayVU the license granted herein to the Data; and (v) the Data does not infringe or violate any patents, copyrights, trademarks or other intellectual property, proprietary or privacy rights of any third party. You shall remain
                        solely responsible and liable for your Data, including without limitation for your use of and reliance upon your Data, and you expressly release SayVU from any and all liability arising from any such activities. SayVU has no obligation
                        to monitor, examine or evaluate the content, accuracy, completeness, timeliness, validity, copyright compliance, legality or any other aspect of your Data, or to screen any of your Data. SayVU do not provide data recovery or reconstruction
                        services. </li>
                    <li>2.8.5 You understand and acknowledge that the Internet and communications over it may not be secure, and that connecting to it provides the opportunity for unauthorized access to computer systems, networks, and all data stored therein.
                        The information and data transmitted through the Internet (including, without limitation, the Data) or stored on any equipment through which Internet information is transmitted may not remain confidential and SayVU makes no representation
                        or warranty regarding privacy, security, authenticity, non-corruption or destruction of any such information. Use of any information transmitted or obtained over the Internet is at your own risk and SayVU shall not be responsible
                        to you for any adverse consequence or loss whatsoever from use of the Internet.</li>
                    <li>2.8.6 You also may be asked by clicking an "I agree" button on a specific push notice/popup window to approve the use of your smartphone`s microphone and/or camera by the Application. Your approval to such use shall allow SayVU to
                        collect, use and store such information, according to the terms specified in this License and pursuant to the Privacy Policy.</li>
                    <li>2.8.7 IF YOU DO NOT AGREE TO APPROVE TO COLLECT, USE, STORE, TRANSMIT OR DISPLAY THE DATA AS SET FORTH AND IN ACCORDANCE WITH THIS SECTION AND THE PRIVACY POLICY, PLEASE DO NOT INSTALL OR USE THE APPLICATION.</li>
                </ul>
            </ul>
            &nbsp;
            <li><strong>Termination:</strong></li>
            <ul>
                <li>3.1 Either you or SayVU may terminate this EULA: (i) for cause upon fourteen (14) days written notice to the other party in the event of a material breach by the other party provided such breach remains uncured at the expiration of such
                    fourteen days’ period, or (ii) immediately if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors.
                </li>
                <li>3.2 Promptly upon termination, you must cease all use of the Application and destroy all copies of the Application in your possession or control. SayVU’s termination will not limit any of SayVU’s other rights or remedies at law or in equity.
                    Any unpaid fees covering that portion of the Term to the effective date of termination shall be paid by you at the effective date of termination.</li>
                <li>3.3 Sections 4-7 of this License shall survive termination or expiration of this License for any reason.</li>
            </ul>

            <li><strong> Disclaimer of Warranties:</strong></li>
            <p>TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THE APPLICATION IS PROVIDED TO YOU “AS IS,” WITH ALL FAULTS, WITHOUT WARRANTY OF ANY KIND, WITHOUT PERFORMANCE ASSURANCES OR GUARANTEES OF ANY KIND, AND YOUR USE IS AT YOUR SOLE RISK.
                THE ENTIRE RISK OF SATISFACTORY QUALITY AND PERFORMANCE RESIDES WITH YOU. SayVU AND ANY ONE ON ITS BEHALF DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL EXPRESS, IMPLIED OR STATUTORY WARRANTIES, INCLUDING IMPLIED WARRANTIES OF CONDITION,
                UNINTERRUPTED USE, MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OF THIRD PARTY RIGHTS, AND WARRANTIES (IF ANY) ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. SayVU DOES NOT WARRANT
                AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE APPLICATION; THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS; THAT OPERATION OF THE APPLICATION WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT THE SOFTWARE WILL BE INTEROPERATE OR THAT THE
                APPLICATION WILL BE COMPATIBLE WITH THIRD PARTY SOFTWARE OR THAT ANY ERRORS IN THE APPLICATION WILL BE CORRECTED. NO ORAL OR WRITTEN ADVICE PROVIDED BY SayVU OR ANY AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.</p>

            <li><strong>Limitation of Liability:</strong></li>
            <p>TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT SHALL SayVU, ITS SUBSIDIARIES OR ITS AFFILIATES OR ANY ONE ON ITS BEHALF, BE LIABLE TO YOU AND/OR TO ANY ONE ON YOUR BEHALF, DIRECTLY OR INDIRECTLY, FOR ANY PERSONAL INJURY,
                PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION AND/OR ANY FORM OF DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                FROM ANY CAUSES OF ACTION ARISING OUT OF OR RELATED TO: (i) THIS LICENSE OR THE APPLICATION, WHETHER ARISING IN TORT (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE, WHETHER OR NOT SayVU HAS BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGE; (ii) ANY RESPONSE OF PARTIAL RESPONSE OR FAILURE TO RESPOND AND/OR ANY ACTION AND/OR OMMISSION OF ANY OF THE APPROVED RECEIVERS AND/OR OF ANY ONE ON THEIR BEHALF, DIRECTLY OR INDIRECTLY, FOR ANY REPORT MADE BY YOU AND/OR
                ANY ONE ON YOUR BEHALF BY USING THE APPLICATION, DIRECTLY OR INDIRECTLY (VALID OR FALSE); (ii) ANY OMMISSION AND/OR FAILURE AND/OR FORCE MAJORE, OF ANY KIND – DIRECTLY OR INDIRECTLY, TO TRANSMIT, TRANSFER, STREAM, BROADCAST, SEND AND/OR
                OTHERWISE MAKE AVAILABLE YOUR REPORT AND/OR ANY RELEVANT/NECESSARY DATA/INFORMATION INCLUDING BUT NOT LIMITED TO, ANY OMMISSION AND/OR FAILURE AND/OR FORCE MAJORE ARISING FROM THE SYSTEM, COMMUNICATION, MOBILE SERVICES, INTERNET/COMPUTER
                MALFUNCTION AND/OR FAILURE AND/OR ANY OTHER CAUSE, INCLUDING BUT NOT LIMITED TO, ANY MALICIOUS ACT/OMMISSION OF A THIRD PARTY AND/OR COMPUTER/INTERNET/CELLULAR HACKING; (iv) ANY MISUSE AND/OR FALSE REPORT AND/OR ANY MALICIOUS USE OF THE
                APPLICATION; (v) ANY USE OR MISUSE MADE BY THE APPROVED RECEIVERS OF ANY REPORT AND/OR DATA AND/OR INFORMATION RECEIVED VIA THE APPLICATION. IN NO WAY DEROGATING FROM THE FOREGOING, IN NO EVENT SayVU SHALL BE LIABLE FOR; (i) THE CONTENT/CONTEXT
                OF ANY REPORT, INCLUDING BUT NOT LIMITED TO, ANY WRITTEN REPORT AS WELL AS ANY VIDEO, VOICE AND/OR PICTURE INCLUDED IN SUCH REPORT; (ii) THE ACTUAL RECEIPT OF THE REPORT BY THE APPROVED RECEIVERS; (iii) THE ACTUAL RESPONSE, INCLUDING BUT
                NOT LIMITED TO, ANY FAILURE TO RESPOND BY ANY APPROVED RECEIVERS, AND/OR ANY RESULT/CONSEQUENCE OF SUCH RESPONSE OR FAILURE TO RESPOND.
            </p>
            <li><strong>Limitation of Liability is a Material Term of this License:</strong></li>
            <p>You agree that the provisions in this License that limit the liability of SayVU are essential terms of this License. The foregoing limitations of liability apply even if any remedies described in this License fail in their essential purpose.
            </p>
            <li><strong>Indemnity:</strong></li>
            <p>You agree to indemnify and hold SayVU and its affiliates, employees, directors, and officers harmless from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from: (i) any violation by you
                of any provision in this EULA; (ii) actions against SayVU by any third parties in connection with your acts or omissions hereunder, and (iii) any other claim in connection with your use of the Application.</p>

            <li><strong> Compliance with Anti-Corruption Laws:</strong></li>
            <p>In using the License hereunder, you agree to comply with all applicable anti-corruption and anti- bribery laws, including the requirements and spirit of the FCPA and the OECD Convention on Combating Bribery of Foreign Public Officials in International
                Business Transactions dated November 21, 1997.</p>

            <li><strong>	Severability and Survival:</strong></li>
            <p>If any provision of this License is illegal or unenforceable under applicable law, such provisions shall be amended to achieve as closely as possible the effect of the original term and all other provisions shall continue in full force and
                effect.</p>

            <li><strong> Injunctive Relief:</strong></li>
            <p>You agree that a breach of this License may cause irreparable injury to SayVU for which monetary damages would not be an adequate remedy and SayVU shall be entitled to seek equitable relief in addition to any remedies it may have hereunder
                or at law.
            </p>

            <li><strong> Governing Law:</strong></li>
            <p>The laws of the state of Israel, govern this License and your use of the Application; and (ii) you expressly agree that exclusive jurisdiction for any claim or action arising out of or relating to this License and/or your use of the Application
                shall be the Courts of Tel-Aviv, Israel, and you expressly consent to the exercise of personal jurisdiction of such courts. The parties agree that the UN Convention on Contracts for the International Sale of Goods (Vienna, 1980) shall
                not apply to this License or to any dispute or transaction arising out of this License.
            </p>

            <li><strong> No Third Party Beneficiaries</strong></li>
            <p>This EULA is made for the benefit of the parties, and is not intended to benefit any third party or be enforceable by any third party. The rights of the parties to terminate, rescind, or agree any amendment, waiver, variation or settlement
                under or relating to this EULA are not subject to the consent of any third party.</p>

            <li><strong> Miscellaneous:</strong></li>
            <p>This License constitutes the entire agreement between you and SayVU with respect to the Application and supersedes all prior or contemporaneous understandings regarding such subject matter. No failure to exercise, and no delay in exercising,
                on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of any other right hereunder. In the event
                of a conflict between this License and any applicable purchase or other terms, the terms of this License shall prevail.</p>

        </ol>


        <p><strong><u>Privacy Policy</u> </strong></p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>


        <p><strong><u>Interpretation and Definitions</u> </strong></p>
        <p>Interpretation</p>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

        <p>Definitions</p>
        <p>For the purposes of this Privacy Policy:</p>

        <ul class="list-point">
            <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
            <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election
                of directors or other managing authority.</li>
            <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named SayVU</li>
            <li><strong>Business</strong>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers'
                personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of
                California.</li>
            <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to SayVU Technologies Ltd., Eliezer Kashani 13/18 Tel Aviv, Israel. &nbsp; For the purpose of the GDPR, the Company is the Data Controller.</li>
            <li><strong>Consumer</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than
                a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.</li>
            <li><strong>Country</strong> refers to: Israel</li>
            <li><strong>Data Controller</strong>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal
                Data.</li>
            <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
            <li><strong>Do Not Track</strong> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet
                users to control the tracking of their online activities across websites.</li>
            <li><strong>Facebook Fan Page</strong> is a public profile named SayVU specifically created by the Company on the Facebook social network, accessible from <a href="https://www.facebook.com/SayVUtech/">https://www.facebook.com/SayVUtech/ </a></li>
            <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
                <br /> For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic,
                mental, economic, cultural or social identity.
                <br /> For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
            </li>
            <li><strong>Sale</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic
                or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration.</li>
            <li><strong>Service</strong> refers to the Application.</li>
            <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service
                on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</li>
            <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                <br /> Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.
            </li>
        </ul>
        <p><strong><u>Collecting and Using Your Personal Data</u></strong></p>
        <p>Types of Data Collected</p>
        <p>Personal Data</p>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
        <ul class="list-point">
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Usage Data</li>
        </ul>
        <p>Usage Data</p>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data.</p>
        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device,
            Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
        <p>Information Collected while Using the Application</p>
        <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
        <ul class="list-point">
            <li>Information regarding your location, including background location that is sampled on three occasions: 1) Real-time monitoring for first responders during their shift, 2) Activating an alert while the application is closed, 3) When sending
                you a notice according to your current location. You may opt out at any given time by exiting a shift or disable background activities.</li>
            <li>Information from your Device's phone book (contacts list)</li>
            <li>Pictures and other information from your Device's camera and photo library</li>
        </ul>

        <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
        <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
        <p>Use of Your Personal Data</p>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul class="list-point">
            <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
            <li><strong>To manage Your Account</strong>: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
            <li><strong>For the performance of a contract</strong>: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
            <li><strong>To contact You</strong>: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related
                to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
            <li><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to
                receive such information.</li>
            <li><strong>To manage Your requests</strong>: To attend and manage Your requests to Us.</li>
            <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern
                or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
            <li><strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.</li>
        </ul>
        <p>We may share Your personal information in the following situations:</p>
        <ul class="list-point">
            <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
            <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to
                another company.</li>
            <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under common control with Us.</li>
            <li><strong>With business partners</strong>: We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
            <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
            <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
        </ul>

        <p>Retention of Your Personal Data</p>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if
            we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or
            We are legally obligated to retain this data for longer time periods.</p>
        <p>Transfer of Your Personal Data</p>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained
            on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are
            adequate controls in place including the security of Your data and other personal information.</p>
        <p>Disclosure of Your Personal Data</p>
        <p>Business Transactions</p>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
        <p>Law enforcement</p>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        <p>Other legal requirements</p>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>

        <ul class="list-point">
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
        </ul>

        <p>Security of Your Personal Data</p>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal
            Data, We cannot guarantee its absolute security.</p>

        <p><strong><u>GDPR Privacy</u></strong></p>
        <p>Legal Basis for Processing Personal Data under GDPR</p>
        <p>We may process Personal Data under the following conditions:</p>

        <ul class="list-point">
            <li><strong>Consent:</strong> You have given Your consent for processing Personal Data for one or more specific purposes.</li>
            <li><strong>Performance of a contract:</strong> Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.</li>
            <li><strong>Legal obligations: </strong>Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.</li>
            <li><strong>Vital interests:</strong> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.</li>
            <li><strong>Public interests:</strong> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.</li>
            <li><strong>Legitimate interests:</strong> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.</li>
        </ul>

        <p>In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter
            into a contract.</p>
        <p>Your Rights under the GDPR </p>
        <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.</p>
        <p>You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>

        <ul class="list-point">
            <li><strong>Request access to Your Personal Data.</strong> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account
                settings section. If you are unable </li>
            <li><strong>Request correction of the Personal Data that We hold about You.</strong> You have the right to have any incomplete or inaccurate information We hold about You corrected.</li>
            <li><strong>Object to processing of Your Personal Data.</strong> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want
                to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.</li>
            <li><strong>Request erasure of Your Personal Data.</strong> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</li>
            <li><strong>Request the transfer of Your Personal Data.</strong> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies
                to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.</li>
            <li><strong>Withdraw Your consent.</strong> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.</li>
        </ul>

        <p>Exercising of Your GDPR Data Protection Rights</p>
        <p>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond
            to You as soon as possible.</p>
        <p>You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the
            EEA.</p>
        <p><strong><u>Facebook Fan Page</u></strong></p>
        <p>Data Controller for the Facebook Fan Page</p>
        <p>The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page <a href="https://www.facebook.com/SayVUtech/">https://www.facebook.com/SayVUtech/</a>, the Company and the operator
            of the social network Facebook are Joint Controllers.</p>
        <p>The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the Facebook Terms of Service: <a href="https://www.facebook.com/terms.php">https://www.facebook.com/terms.php</a></p>
        <p>Visit the Facebook Privacy Policy <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a> for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN,
            Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.</p>
        <p>Facebook Insights</p>
        <p>We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users.</p>
        <p>For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period.</p>
        <p>Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use
            Facebook services.</p>
        <p>For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here: <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p>

        <p><u><strong>CCPA Privacy</strong></u></p>
        <p>This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.</p>
        <p>Categories of Personal Information Collected</p>
        <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories
            of personal information which we may collect or may have been collected from California residents within the last twelve (12) months.</p>
        <p>Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact collected by Us, but reflects our good faith belief
            to the best of our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information
            directly to Us.</p>

        <ul class="list-point">
            <li>Category A: Identifiers.</li>
            <p>Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.</p>
            <p>Collected: Yes.</p>

            <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</li>
            <p>Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment,
                employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with
                other categories.</p>
            <p>Collected: Yes.</p>

            <li>Category C: Protected classification characteristics under California or federal law.</li>
            <p>Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy
                or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
            <p>Collected: No.</p>

            <li>Category D: Commercial information.</li>
            <p>Examples: Records and history of products or services purchased or considered.</p>
            <p>Collected: No.</p>

            <li>Category E: Biometric information.</li>
            <p>Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina
                scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
            <p>Collected: No.</p>

            <li>Category F: Internet or other similar network activity.</li>
            <p>Examples: Interaction with our Service or advertisement.</p>
            <p>Collected: Yes.</p>

            <li>Category G: Geolocation data.</li>
            <p>Examples: Approximate physical location.</p>
            <p>Collected: Yes.</p>

            <li>Category H: Sensory data.</li>
            <p>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</p>
            <p>Collected: No.</p>

            <li>Category I: Professional or employment-related information.</li>
            <p>Examples: Current or past job history or performance evaluations.</p>
            <p>Collected: No.</p>

            <li>Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</li>
            <p>Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial
                information, or student disciplinary records.</p>
            <p>Collected: No.</p>

            <li>Category K: Inferences drawn from other personal information.</li>
            <p>Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
            <p>Collected: No.</p>
        </ul>

        <p>Under CCPA, personal information does not include:</p>

        <ul class="list-point">
            <li>Publicly available information from government records</li>
            <li>Deidentified or aggregated consumer information</li>
            <li>Information excluded from the CCPA's scope, such as:</li>
            <ul>
                <li> Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data</li>
                <li> Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection
                    Act of 1994</li>
            </ul>
        </ul>

        <p>Sources of Personal Information</p>
        <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
        <ul class="list-point">
            <li>Directly from You. For example, from the forms You complete on our Service, preferences You express or provide through our Service.</li>
            <li>Indirectly from You. For example, from observing Your activity on our Service.</li>
            <li>Automatically from You. For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.</li>
            <li>From Service Providers. For example, or other third-party vendors that We use to provide the Service to You.</li>
        </ul>

        <p>Use of Personal Information for Business Purposes or Commercial Purposes</p>
        <p>We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples:</p>
        <ul class="list-point">
            <li>To operate our Service and provide You with our Service.</li>
            <li>To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.</li>
            <li>To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry.</li>
            <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
            <li>As described to You when collecting Your personal information or as otherwise set forth in the CCPA.</li>
            <li>For internal administrative and auditing purposes.</li>
            <li>To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.</li>
        </ul>

        <p>Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data" section.</p>
        <p>If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.</p>
        <p>Disclosure of Personal Information for Business Purposes or Commercial Purposes</p>
        <p>We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:</p>

        <ul class="list-point">
            <li>Category A: Identifiers</li>
            <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
            <li>Category F: Internet or other similar network activity</li>
            <li>Category G: Geolocation data</li>
        </ul>

        <p>Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed, but reflects our good faith belief to the best of our knowledge that
            some of that information from the applicable category may be and may have been disclosed.</p>
        <p>When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose
            except performing the contract.</p>
        <p>Sale of Personal Information</p>
        <p>As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer's personal information
            by the business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.</p>
        <p>Please note that the categories listed below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact sold, but reflects our good faith belief to the best of our knowledge that some
            of that information from the applicable category may be and may have been shared for value in return.</p>
        <p>We may sell and may have sold in the last twelve (12) months the following categories of personal information:</p>

        <ul class="list-point">
            <li>Category A: Identifiers</li>
            <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
            <li>Category F: Internet or other similar network activity</li>
            <li>Category G: Geolocation data</li>
        </ul>


        <p>Share of Personal Information</p>
        <p>We may share Your personal information identified in the above categories with the following categories of third parties:</p>

        <ul class="list-point">
            <li>Service Providers</li>
            <li>Our affiliates</li>
            <li>Our business partners</li>
            <li>Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You</li>
        </ul>

        <p>Sale of Personal Information of Minors Under 16 Years of Age</p>
        <p>We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and 16 years of age, or the parent
            or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request
            to Us by contacting Us.</p>
        <p>If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information.</p>
        <p>Your Rights under the CCPA</p>
        <p>The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:</p>

        <ul class="list-point">
            <li>The right to notice. You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</li>
            <li>The right to request. Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request,
                We will disclose to You:</li>
            <ul>
                <li>The categories of personal information We collected about You</li>
                <li>The categories of sources for the personal information We collected about You</li>
                <li>Our business or commercial purpose for collecting or selling that personal information</li>
                <li>The categories of third parties with whom We share that personal information</li>
                <li>The specific pieces of personal information We collected about You</li>
                <li>If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You: </li>
                <ul>
                    <li>The categories of personal information categories sold</li>
                    <li>The categories of personal information categories disclosed</li>
                </ul>
            </ul>
            <li>The right to say no to the sale of Personal Data (opt-out). You have the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us.</li>
            <li>The right to delete Personal Data. You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your
                personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:</li>
            <ul>
                <li>Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise
                    perform our contract with You.</li>
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously
                    impair the research's achievement, if You previously provided informed consent.</li>
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.</li>
                <li>Comply with a legal obligation.</li>
                <li>Make other internal and lawful uses of that information that are compatible with the context in which You provided it.</li>
            </ul>
            <li>The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:</li>
            <ul>
                <li>Denying goods or services to You</li>
                <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
                <li>Providing a different level or quality of goods or services to You</li>
                <li>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services</li>
            </ul>
        </ul>

        <p>Exercising Your CCPA Data Protection Rights</p>
        <p>In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:</p>
        <ul class="list-point">
            <li>By email: support@sayvu.com</li>
            <li>By visiting this page on our website: https://www.sayvu.com/contact</li>
            <li>By phone number: +97239477177</li>
            <li>By mail: Eliezer Kashani 13/18 Tel Aviv, Isarel</li>
        </ul>

        <p>Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information.</p>
        <p>Your request to Us must:</p>
        <ul class="list-point">
            <li>Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative</li>
            <li>Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it</li>
        </ul>

        <p>We cannot respond to Your request or provide You with the required information if we cannot:</p>
        <ul class="list-point">
            <li>Verify Your identity or authority to make the request</li>
            <li>And confirm that the personal information relates to You</li>
        </ul>

        <p>We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary
            and with prior notice.</p>
        <p>Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.</p>
        <p>For data portability requests, We will select a format to provide Your personal information that is readily useable and should allow You to transmit the information from one entity to another entity without hindrance.</p>
        <p>Do Not Sell My Personal Information</p>
        <p>You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.</p>

        <p><u><strong>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</strong></u></p>
        <p>Our Service does not respond to Do Not Track signals.</p>
        <p>However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable
            DNT by visiting the preferences or settings page of Your web browser.</p>

        <p><u><strong>Children's Privacy</strong></u></p>
        <p>The Service may contain content appropriate for children under the age of 13. As a parent, you should know that through the Service children under the age of 13 may participate in activities that involve the collection or use of personal information.
            We use reasonable efforts to ensure that before we collect any personal information from a child, the child's parent receives notice of and consents to our personal information practices.</p>
        <p>We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these Users. If We need to rely on consent
            as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
        <p>We may ask a User to verify its date of birth before collecting any personal information from them. If the User is under the age of 13, the Service will be either blocked or redirected to a parental consent process.</p>
        <p>Information Collected from Children Under the Age of 13</p>
        <p>The Company may collect and store persistent identifiers such as cookies or IP addresses from Children without parental consent for the purpose of supporting the internal operation of the Service.</p>
        <p>We may collect and store other personal information about children if this information is submitted by a child with prior parent consent or by the parent or guardian of the child.</p>
        <p>The Company may collect and store the following types of personal information about a child when submitted by a child with prior parental consent or by the parent or guardian of the child:</p>

        <ul class="list-point">
            <li>First and/or last name</li>
            <li>Date of birth</li>
            <li> Gender </li>
            <li>Grade level</li>
            <li>Email address </li>
            <li>Telephone number</li>
            <li>Parent's or guardian's name</li>
            <li>Parent's or guardian's email address</li>
        </ul>

        <p> For further details on the information We might collect, You can refer to the "Types of Data Collected" section of this Privacy Policy. We follow our standard Privacy Policy for the disclosure of personal information collected from and about children.
            </p>
        <p>Parental Access</p>
        <p>A parent who has already given the Company permission to collect and use his child personal information can, at any time:</p>

        <ul class="list-point">
            <li>Review, correct or delete the child's personal information</li>
            <li>Discontinue further collection or use of the child's personal information</li>
        </ul>

        <p>To make such a request, You can write to Us using the contact information provided in this Privacy Policy.</p>

        <p><u><strong>Your California Privacy Rights (California's Shine the Light law)</strong></u></p>
        <p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the
            third parties' direct marketing purposes.</p>
        <p>If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.</p>

        <p><u><strong>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</strong></u></p>
        <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly
            posted.</p>
        <p>To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
        <p>Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>

        <p><u><strong>Links to Other Websites</strong></u></p>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

        <p><u><strong>  Changes to this Privacy Policy </strong></u></p>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
        <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <p><u><strong> Contact Us </strong></u></p>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>

        <ul class="list-point">
            <li>By email: support@sayvu.com</li>
            <li>By visiting this page on our website: https://www.sayvu.com/contact</li>
            <li>By phone number: +97239477177</li>
            <li>By mail: Eliezer Kashani 13/18 Tel Aviv, Isarel</li>

        </ul>

    </div>
        </Layout>
    ) : (
        null
    )
}

export default AboutUs
