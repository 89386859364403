import React, {useState} from 'react';
import "./rashContact.css"
import "../../css/typography.css"
import 'react-phone-number-input/style.css'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'
import { Link } from "gatsby"

const FormContact = () => {
    const [value, setValue] = useState();
    const [checked, setChecked] = useState(false);

return (
    <>
    <form
        className="form-contact-antd-rash"
        method="post" 
        action="https://formspree.io/f/mdoyakdq"
    >
        <label>
            <p>שם</p>      
            <input dir='rtl' required type="text" name="Name" className="form-contact-antd-input-rash form-contact-input-input-rash"/>
        </label>
        <label>
            <p>אימייל</p>
            <input dir='rtl' required type="email" name="_replyto" className="form-contact-antd-input-rash form-contact-input-input-rash"/>
        </label>
        <label>
            <p>מספר טלפון</p>
            <input type="text" name="phone" className="form-contact-antd-input-rash form-contact-input-input-rash"/>
        </label>
        <input type="text" name="_gotcha" style={{display: 'none'}} />
        <label>
            <button type="submit"  className="form-contact-antd-button-rash">שלח/י</button>
        </label>
    </form>
    </>
);
};

export default FormContact